@import '../basic/variables';

header {
  overflow-x: clip;
  .header__container {
    display: inline-block; }

  .header__logobox {
    display: inline-block;
    width: 10rem;
    padding: 2rem 0 0.9rem 1.5rem;

    @include media-tablet {
      padding: 1.2rem 0 0.9rem 3.1rem;
      width: fit-content; }

    img {
      width: 100%;
      height: auto; } }

  .nav-item {
    margin-top: 1.3rem;

    .nav-link {
      padding-top: 0.9rem; } }

  .header__nav {
    float: right;
    height: 6.1rem;
    margin-right: 0;
    @include media-tablet {
      margin-right: 1.1rem; }

    .dropdown {
      display: none;
      @include media-tablet {
        display: block; }
      a {
        color: $white;
        font-size: 14px; } }

    .header__nav-item {
      margin-left: 2rem;
      display: none;
      @include media-tablet {
        display: block; }

      &.header__button {
        display: inline-block;

        &:empty {
          display: none; } }

      &.header__nav-item__icon {
        .nav-link {
          position: relative;
          padding-left: 1.8rem; }

        .nav-item-icon {
          width: 1.2rem;
          height: 1.5rem;
          position: absolute;
          left: 0;
          top: 1.15rem; } }
      a {
        color: $white;
        font-size: 14px; } }

    .dropdown-menu {
      padding: 0;
      .dropdown-item {
        color: $black;
        padding: 1rem;
        &:hover {
          color: $white;
          background-color: $daintree; } } } }

  .mobileMenu__wrap {
    position: relative;

    @include media-tablet {
      display: none; }
    .mobileMenu__burger-menu {
      height: 100%;
      width: 100%;
      padding: 2.65rem;

      &::after {
        content: '';
        display: block;
        width: 1.4rem;
        height: 0.4rem;
        border-bottom: .2rem solid $white;
        border-top: .2rem solid $white; }

      @include media-tablet {
        display: none; } }

    .mobileMenu__animation-container {
      padding: 1.5rem 3.5rem;
      position: absolute;
      height: 100vh;
      background-color: $daintree;
      width: 37.8rem;
      top: 0;
      left: 100%;
      z-index: 20;

      &.open {
        animation: 0.5s menu_animation_open;
        animation-fill-mode: forwards;
        box-shadow: 0 0 1.3rem 0 rgba(0,0,0,0.65); }

      &.close {
        animation: 0.5s menu_animation_close;
        animation-fill-mode: forwards;

        @keyframes menu_animation_open {
          from {
            left: 100%; }
          to {
            left: -30.8rem; } }
        @keyframes menu_animation_close {
          from {
            left: -30.8rem; }
          to {
            left: 100%; } } }

      .mobileMenu__close-button {
        position: absolute;
        right: 1.5rem;
        top: .5rem;
        width: 5rem;
        height: 5rem;
        &:after {
          content: " \002B";
          font-size: 4rem;
          transform: rotate(-45deg);
          display: block;
          color: $white;
          position: absolute;
          top: -.5rem;
          right: 2rem; }

        + div {
          margin-top: 7rem; } }

      .header__button {
        color: $white;
        padding: 2rem 5rem !important;
        min-width: 21.5rem;
        min-height: 6.4rem;
        font-weight: bold;
        .nav-item-icon {
          left: -2rem; } }

      .mobileMenu__nav {
        display: inline-block;
        text-align: right;
        margin-bottom: 23px; }

      .mobileMenu__nav, .mobileMenu__buttons {
        .nav-link {
          min-width: 20.5rem;
          text-align: left;
          margin-bottom: 1rem; } }

      .mobileMenu__language-menu {
        margin-block: 2.8rem;
        width: 100%;
        display: inline-block;

        a {
          padding: 0 0 5px 0;
          margin: 2.1rem;
          font-size: 1.6rem;
          color: $white;
          clear: none;
          display: block;
          float: right;
          width: 2.2rem;
          &:first-child {
            margin-right: 0; }

          &.activeLang, &:hover {
            border-bottom: 1px solid white; }

          &:hover {
            background: transparent; } } }

      .mobileMenu__nav-item {
        margin-top: 1rem;
        text-align: right;
        .nav-link {
          padding-block: .7rem;
          display: inline-block;
          color: $white;
          font-size: 1.6rem;
          font-weight: bold; }

        &--active {
          .nav-link {
            background-color: rgba(255,255,255,.1);
            border-radius: .3rem; } } } } } }
