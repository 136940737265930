//// COLORS ###################
$white: #fff;
$black: #000;

$daintree: #00282d;
$daintree-half-transparent: rgba(0,40,45,0.5);
$white-half-transparent: rgba(255,255,255,0.5);
$light-green: #e6f5f6;
$tech-green: #c4fd3a;
$white-60: #687e81;
$white-50: #819396;
$anthrazit: #181818;

$special-input-border-color: #ddd;

$turquoise-2: #005f6b;
$turquoise-3: #0392a4;

//// BREAKPOINTS #####################
$breakpoint-mobile:     375px;
$breakpoint-tablet:     768px;
$breakpoint-desktop:    821px;
$breakpoint-desktop-large:    1025px;
$breakpoint-small-widescreen:    1440px;
$breakpoint-widescreen: 1920px;

//// TRANSITIONS #####################
$transition-duration:         .15s;
$transition-timing-function:  ease-in-out;

//// FONTS ######################
$font-regular: "DIN Pro", Arial, sans-serif;
$font-bold: "DIN Pro Bold";
