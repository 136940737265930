@import '../basic/variables';
.nav-link.button-main-wrap, .button-main-wrap {
  padding: 0 !important;
  margin: 0;
  position: relative;
  z-index: 1;
  display: inline-block;

  &:hover {
    .button-main {
      background: $anthrazit;
      color: $white;
      transform: translateY(-.7rem);
      box-shadow: -.1rem 1rem 3.7rem -2.2rem $black;
      .is-landingpage & {
        color: $black;
        background: transparent;
        box-shadow: none; } } }

  &--dark:hover {
    .button-main {
      background-color: $white;
      color: $anthrazit !important;
      .is-landingpage &,
      &.button-main--inverted {
        background-color: transparent;
        border-color: $white;
        color: $white !important; } } }

  &--half-dark:hover {
    .button-main {
      background-color: $white;
      color: $anthrazit;
      .is-landingpage & {
        background-color: rgba(0,0,0,0.5);
        color: $white; } } }

  &--small, &--static {
    &:hover .button-main {
      transform: none;
      box-shadow: none; } }

  &:hover .nav-item-icon {
    filter: invert(1); }

  .button-main {
    color: $black;
    background: transparent;
    border-radius: 6px;
    border: 0.1rem solid $black;
    position: relative;
    padding: 2rem 4.5rem;
    line-height: 1.25;
    text-decoration: none;
    @include font-bold;
    font-size: 1.6rem;
    display: block;
    transition: background-color $transition-duration $transition-timing-function, color $transition-duration $transition-timing-function, transform $transition-duration $transition-timing-function, box-shadow $transition-duration $transition-timing-function;
    margin-top: .8rem;
    .is-landingpage & {
      background: $anthrazit;
      color: $white; }

    @include media-desktop {
      margin-top: 1rem; }

    @include media-desktop-large {
      margin-top: 0; }

    &--dark {
      background-color: transparent;
      border-color: $white;
      color: $white;

      .is-landingpage &,
      &.button-main--inverted {
        background-color: $white;
        color: $anthrazit; } }

    &--half-dark {
      background-color: rgba(0,0,0,0.5);
      border-color: $white;
      color: $white;
      .is-landingpage & {
        background-color: $white;
        color: $anthrazit; } }

    &--small {
      padding: 0.8rem 1.2rem !important;
      margin-top: 0; }

    .nav-item-icon {
      width: 1.2rem;
      height: 1.5rem;
      position: relative;
      left: -1.5rem;
      top: 0.2rem;
      transition: filter $transition-duration $transition-timing-function; } } }
