.text-element, .main__text-element.dynamic h1.big-headline {
  padding: 0 2rem;
  margin: 5rem 0;

  &--inner {
    max-width: 90rem;

    img {
      max-width: 100%; } }

  @include media-tablet {
    padding: 0 5rem;
    margin: 8rem auto;
    max-width: 120rem; }

  @include media-desktop-large {
    padding: 0 9.5rem; } }

.main__text-element.dynamic h1.big-headline {
  margin: 3rem 0 0 0;
  padding-bottom: 0;

  + .text-element {
    margin-top: 1.6rem; }

  @include media-tablet {
    margin: 8rem auto 0;

    + .text-element {
      margin-top: 3.2rem; } } }

