.statement {
  margin: 14rem 0;
  padding: 2rem;
  max-width: 123rem;
  font-family: "DIN Pro bold", Arial, sans-serif;
  transform: translateY(30%);
  opacity: 0;
  line-height: 6rem;

  @include media-tablet() {
    margin: 20rem auto;
    padding: 0 9rem;
    line-height: 8rem; }

  @include media-desktop-large() {
    line-height: 10rem; } }
