.accordion-module {
  margin: 7rem 2rem;

  @include media-tablet {
    padding: 0 5rem;
    margin: 10rem auto;
    max-width: 120rem;

    &--inner {
      max-width: 94rem; } }

  @include media-desktop-large {
    padding: 0 9.5rem; }

  &--intro {
    margin-bottom: 3rem;
    @include media-tablet {
      margin-bottom: 3.5rem; }

    @include media-desktop-large {
      margin-bottom: 5rem; } }

  .accordion-item {
    border: none;
    border-radius: 0 !important;
    border-top: .1rem solid $black;
    border-bottom: .1rem solid $black; }

  h4.accordion-header {
    margin: 0; }

  .accordion-button {
    font-size: inherit;
    cursor: pointer;
    padding: 1.5rem;
    @include plusMinus(1.7rem, .3rem, $black);

    .animated-icon {
      margin-right: 1.5rem;
      flex-shrink: 0; }

    @include media-tablet {
      padding: 2.5rem 2rem;
      @include plusMinus(2rem, .4rem, $black);

      .animated-icon {
        margin-right: 3rem; } }

    @include media-desktop-large {
      padding: 3rem 2rem; }

    &:after {
      display: none; }

    &:focus {
      box-shadow: none; }

    &:not(.collapsed) {
      color: $black;
      background: $white;
      box-shadow: none; } }

  .accordion-body {
    padding-top: 0; }

  .buttons {
    margin-top: 4rem;

    @include media-tablet {
      margin-top: 6.5rem;
      text-align: center; }

    a:not(:last-child) {
      margin-right: 3rem; } } }
