* {
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    overflow: hidden; }

  p a, p span a, ul li a {
      @include font-regular;
      color: $turquoise-3;
      border-bottom: 0.1rem solid;
      text-decoration: none;

      &:hover {
        border-bottom-color: transparent; } }

  .header-subline {
    font-size: 1.6rem;
    @include font-bold;

    p:empty {
      width: 6.4rem;
      height: 0.8rem;
      background: $daintree;
      margin-top: 1.6rem; }

    &--white {
      color: $white;

      p:empty {
        background: $white; } } } }

// class for wrapping elements of links and buttons which should receive an affiliate-code, but have none
// if the element is empty it will be hidden
.cta-wrap:empty {
  display: none; }
