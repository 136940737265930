.hero {
  video,
  .hero__poster {
    width: 100%;
    height: calc(100vh - 6.1rem);
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;
    z-index: -1; }

  &__text {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 2rem;
    background: rgba(0, 0, 0, 0);
    color: $white;
    bottom: 6.4rem;
    max-width: 57rem;

    &-description {
      p {
        font-size: 1.8rem; } }

    @include media-mobile-xs() {
      bottom: 4rem; }

    @include media-tablet {
      left: 5rem;
      bottom: 8%;
      width: 100%;
      padding: 0 3rem; }

    @include media-desktop {
      &-description {
        p {
          font-size: 2.2rem; } } }

    @include media-desktop-large {
      left: 9.5rem;
      &-description {
        p {
          font-size: 2.4rem; } } }

    @include media-small-widescreen() {
      left: 12rem; }

    @media screen and (min-height: 1080px) and (min-width: 768px) {
      bottom: 27%; }
    @media screen and (min-height: 801px) and (min-width: 768px) {
      bottom: 22%; }
    @media screen and (max-height: 800px) and (min-width: 768px) {
      bottom: 14%; }
    @media screen and (max-height: 540px) {
      bottom: 2rem; } }

  &--no-video {
    width: 100%;
    height: calc(100vh - 6.1rem); } }
