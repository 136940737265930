@import '../basic/variables';
@import '../basic/mixins';

header {
  position: relative;
  z-index: 10;
  height: 6.1rem;
  background-color: $daintree;

  &.has-main {
    @include media-tablet {
      height: 8.1rem;
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: auto; }

      .nav-item {
        margin-top: 0;
        .nav-item-icon {
          top: .7rem !important; }
        .nav-link {
          padding-block: .4rem; } }

      .header__main .nav-item .nav-link {
        padding-block: .5rem; }

      .header__button {
        display: flex !important;
        align-items: center; }

      .header__nav .header__nav-item {
          &--main {
            .nav-link {
              font-size: 1.6rem;
              font-weight: bold;
              &:hover {
                background-color: rgba(255,255,255,.1);
                border-radius: .3rem; } }
            &.header__nav-item--active .nav-link {
              background-color: rgba(255,255,255,.1);
              border-radius: .3rem; } } } } }

  &:not(.has-main) {
    @include media-tablet {
      .header {
        &__group {
          display: block; }
        &__top {
          display: block;
          > div {
            display: inline-block; } } } } }

  .header {
    &__top,
    &__main,
    &__group {
      @include media-tablet {
        display: flex;
        justify-content: flex-end; } }

    &__main {
      padding-block: .4rem; }

    &__group {
      flex-direction: column; } } }
