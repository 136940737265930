.newsletter-module {
  position: relative;

  &__inner {
    padding: 0 2rem;
    margin: 5rem 0;

    @include media-tablet {
      padding: 0 5rem;
      margin: 8rem auto;
      max-width: 120rem; }

    @include media-desktop-large {
      padding-left: 9.5rem; } }

  &__secondwrap {
    max-width: 94rem; }

  &__content {
    max-width: 80rem;
    padding: 4.8rem 0;

    h3, p, li, a, strong {
      color: $white; } }

  &__background {
    background: $daintree;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;

    @media(min-width: 1035px) {
      border-top-right-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem; } }

  .desktop-button {
    display: none; }

  @include media-tablet {
    .desktop-button {
      display: inline-block;
      margin-left: 1rem;
      margin-bottom: 1rem;
      vertical-align: top;

      button {
        padding: 2.3rem 4.5rem; } }

    .mobile-button {
      display: none; } }

  label.checkbox {
    margin: 2.5rem 0;

    p {
      font-size: 1.6rem; } }

  .input-area {
    display: inline-block;
    margin-top: 1.5rem;

    @include media-tablet {
      margin-top: 0; } } }
