.app-store-buttons {
  margin-top: 2.5rem;

  p {
    margin-bottom: 1.6rem; }

  &__btn {
    display: inline-block;
    margin: 0 1rem 1rem 0;

    &:last-child {
      margin: 0; } } }
