.part-amb-comp {
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
    padding: 7rem 2rem 5rem;

    @include media-desktop {
      width: 50%;
      padding: 8rem 5rem; }

    @include media-desktop-large {
      padding: 12rem 9.5rem; } }

  @include media-desktop {
    &__inner {
      max-width: 60rem;
      display: flex;
      flex-direction: column;
      height: 100%; }

    &__bottom-part {
      margin-top: auto; }

    &__intro-list {
      p:last-child {
        margin-bottom: 1.6rem; } }

    &__left .part-amb-comp__inner {
      margin-left: auto; } }

  &__tags {
    padding: 0;

    li {
      display: inline-block;
      list-style-type: none;
      padding: .4rem 1.1rem;
      border: .1rem solid $black;
      border-radius: 3rem;
      margin: 0 .8rem .8rem 0;

      @include media-desktop {
        font-size: 1.6rem; } } }

  &__left {
    background: $light-green; }

  &__right {
    background: $daintree;

    .part-amb-comp__tags li {
      border-color: $white; } } }


