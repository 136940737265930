
@font-face {
  font-family: 'DIN Pro';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local(''), url('../../fonts/DINPro.woff2') format('woff2'), url('../../fonts/DINPro.woff') format('woff'); }

@font-face {
  font-family: 'DIN Pro Bold';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local(''), url('../../fonts/DINPro-Bold.woff2') format('woff2'), url('../../fonts/DINPro-Bold.woff') format('woff'); }

@font-face {
  font-family: 'Orpheus Pro';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local(''), url('../../fonts/OrpheusPro-Regular.woff2') format('woff2'), url('../../fonts/OrpheusPro-Regular.woff') format('woff'); }

