.contactform-module {
  padding: 0 2rem;
  margin: 5rem 0;

  @include media-tablet {
    padding: 0 5rem;
    margin: 3rem auto;
    max-width: 120rem; }

  @include media-desktop-large {
    padding: 0 9.5rem; }

  &__container {
    padding: 3rem 2rem;
    margin: 2rem -2rem 0;
    background: $light-green;
    border-radius: .6rem;

    @include media-tablet {
      margin: 2rem 0 0; }

    @include media-desktop {
      padding: 6rem 2rem;
      margin-top: 4rem; } }

  &__inner {
    max-width: 62rem;
    margin: 0 auto; }

  &__lastrow {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 2.5rem;

    p {
      font-size: 1.6rem;
      margin: 0; }

    .button-main-wrap .button-main--dark {
      background: $daintree;
      border: none; }

    .button-main-wrap--dark:hover .button-main {
      background: $daintree;
      color: $white !important; } }

  &__text-after {
    margin: 6rem 0 4rem;

    p, p > * {
      font-family: $font-bold;
      font-size: 1.8rem;

      @include media-desktop {
        font-size: 2.2rem; }

      @include media-desktop-large {
        font-size: 2.4rem; } } }

  &__howtocontact {
    display: flex;
    flex-wrap: wrap;

    p {
      font-size: 1.8rem;
      @include media-desktop {
        font-size: 2.2rem; }
      @include media-desktop-large {
        font-size: 2.4rem; }

      &.contactform-module__label {
        font-size: 1.2rem;
        font-family: $font-bold;
        margin-bottom: 0;

        + p {
          margin-top: 0; } } }

    > div {
      width: 100%;

      @include media-desktop {
        width: 50%; } } }

  .input-area {
    margin-bottom: 2.3rem;

    input, select, textarea {
      width: 100%;
      border-color: $special-input-border-color;

      &:focus {
        border-color: $daintree-half-transparent; } }

    textarea {
      height: 20rem; } }

  label.checkbox {
    p {
      font-size: 1.6rem; }

    .checkbox__styled-box {
      border-color: $special-input-border-color;
      background: $white; } } }

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity $transition-duration $transition-timing-function;

  &--show {
    opacity: 1;
    pointer-events: auto; }

  &__window {
    background: $white;
    padding: 4rem;
    text-align: center;
    max-width: 60rem;
    border-radius: .6rem;

    p {
      font-size: 1.6rem; } }

  &--success {
    .modal__icon {
      width: 15rem;
      height: 15rem;
      border-radius: 30rem;
      border: .4rem solid $turquoise-2;
      margin: 0 auto 3rem;
      position: relative;

      &::before {
        content: '';
        width: 4rem;
        height: 7.5rem;
        border-bottom: .4rem solid $turquoise-2;
        border-right: .4rem solid $turquoise-2;
        position: absolute;
        top: 2.4rem;
        left: 5.3rem;
        transform: rotate(40deg); } } } }
