.title-box {
  background: $daintree;
  color: $white;
  padding: 8rem 2rem;
  margin-bottom: 6rem;
  border-top: 1px solid $white;

  @include media-tablet {
    padding: 13rem 5rem;
    margin-bottom: 10rem; }

  @include media-desktop-large {
    padding: 16rem 9.5rem;
    margin-bottom: 12rem; }

  h1 {
    color: $white;
    margin: 0;
    padding: 0; } }
