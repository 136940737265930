.two-col-text-right {
  display: block;
  margin: 7rem 2rem;
  @include media-tablet() {
    display: flex;
    justify-content: center;
    margin: 10.5rem auto; }

  div {
    flex: 1; }
  h2 {
    @include media-tablet() {
      max-width: 50rem; } }

  &__left {
    flex-direction: column;
    padding: 0;
    max-width: 60rem;

    >div>div {
      opacity: 0; }

    @include media-tablet() {
      padding: 0 3rem 0 5rem;

      >div>div {
        transform: translateY(-4rem); } }

    @include media-desktop-large() {
      padding: 0 3rem 0 9.5rem; } }

  &__right {
    text-align: left;
    flex-direction: column;
    padding: 0;
    max-width: 60rem;

    >div>div {
      opacity: 0; }

    @include media-tablet() {
      padding: 0 5rem 0 3rem;

      >div>div {
        transform: translateY(6rem); } }

    @include media-desktop-large() {
      padding: 0 9.5rem 0 3rem;

      @include media-desktop {
        margin-top: 0; } } } }




