input[type=email], input[type=text], input[type=tel], input[type=number], input[type=search], input[type=phone], input[type=url], textarea, select {
  border-radius: .6rem;
  background: $white;
  padding: 3.3rem 2rem 1.3rem;
  font-size: 1.6rem;
  border: .1rem solid $daintree-half-transparent;
  width: 50rem;
  max-width: 100%;
  color: $daintree;
  outline: none;
  transition: border-color $transition-duration $transition-timing-function;

  &::placeholder {
    color: $daintree; }

  &:focus {
    border-color: $daintree; }

  &.input--dark {
    border-color: $white-half-transparent;
    color: $white;
    background: $daintree;

    &::placeholder {
      color: white; }

    &:focus {
      border-color: $white; } } }

select {
  appearance: none;
  position: relative;

  ~ span.input__arrow {
    display: block;
    width: 1rem;
    height: 1rem;
    border-left: .2rem solid $daintree;
    border-bottom: .2rem solid $daintree;
    position: absolute;
    top: 2.6rem;
    right: 2.5rem;
    transform: rotate(-45deg); } }

.input-area {
  position: relative;
  max-width: 100%;

  &__move-up {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.6rem;
    color: $daintree;
    margin: 2.4rem 2rem 2.4rem 1.1rem;
    padding: 0 1rem;
    pointer-events: none;
    transition: all $transition-duration $transition-timing-function;

    &--dark {
      color: $white; } }

  input:focus + label.input-area__move-up,
  textarea:focus + label.input-area__move-up,
  select:focus + label.input-area__move-up,
  input:not(:placeholder-shown) + label.input-area__move-up,
  textarea:not(:placeholder-shown) + label.input-area__move-up,
  select:focus + label.input-area__move-up , select:not([value=""]):valid + label.input-area__move-up {
    transform: translateY(-1.4rem);
    font-size: 1.4rem; } }


label.checkbox {
  display: flex;
  position: relative;
  cursor: pointer;

  input[type=checkbox] {
    opacity: 0;
    pointer-events: none; }

  .checkbox__styled-box {
    width: 2.3rem;
    height: 2.3rem;
    border-radius: .6rem;
    border: .1rem solid $daintree;
    position: absolute;
    left: 0;
    top: 0;
    transition: background $transition-duration $transition-timing-function;

    &::before {
      content: '';
      width: .6rem;
      height: 1rem;
      border-right: .2rem solid $white;
      border-bottom: .2rem solid $white;
      position: absolute;
      top: .3rem;
      left: .6rem;
      opacity: 0;
      transition: all $transition-duration $transition-timing-function; }

    &--dark {
      border-color: $white;

      &::before {
        border-color: $daintree; } } }

  input[type=checkbox]:checked + div.checkbox__styled-box {
    background: $daintree;

    &--dark {
      background: $white; }

    &::before {
      opacity: 1;
      transform: rotate(35deg); } }

  p {
    margin: 0 0 0 1.8rem; } }

button[type=submit] {
  &:hover {
    cursor: pointer; } }

input[name=fax-number-veryold] {
  display: none; }
