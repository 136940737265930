// media queries
@mixin media-mobile-xs() {
  @media (max-width: $breakpoint-mobile) {
    @content; } }

@mixin media-tablet() {
  @media (min-width: $breakpoint-tablet) {
    @content; } }

@mixin media-desktop() {
  @media (min-width: $breakpoint-desktop) {
    @content; } }

@mixin media-desktop-large() {
  @media (min-width: $breakpoint-desktop-large) {
    @content; } }

@mixin media-small-widescreen() {
  @media (min-width: $breakpoint-small-widescreen) {
    @content; } }

@mixin media-widescreen() {
  @media (min-width: $breakpoint-widescreen) {
    @content; } }

@mixin animation-in-viewport() {
  @media (prefers-reduced-motion: no-preference) {
    @content; } }

@mixin font-regular() {
  font-family: $font-regular;
  font-weight: 400;
  font-style: normal;
  color: $black; }

@mixin font-bold() {
  font-family: $font-bold;
  font-weight: 400;
  font-style: normal;
  color: $black; }

@mixin headline-style($font-size, $font-size-desktop, $font-size-desktop-large) {
  @include font-bold;
  color: $black;
  font-size: $font-size;
  @include media-tablet() {
    font-size: $font-size-desktop; }
  @include media-desktop-large() {
    font-size: $font-size-desktop-large; } }

@mixin paragraph-style($font-size, $font-size-desktop, $font-size-desktop-large) {
  @include font-regular;
  font-size: $font-size;
  line-height: 1.4;
  @include media-tablet {
    font-size: $font-size-desktop; }
  @include media-desktop-large() {
    font-size: $font-size-desktop-large; } }

// ANIMATED PLUS-MINUS ICON
// source: https://codepen.io/anon/pen/JNywpQ (slightly changed)

@mixin plusMinus($width, $lineWidth, $color) {
  .animated-icon {
    display: block;
    box-sizing: border-box;
    transition: transform 0.3s;
    width: $width;
    height: $width;
    transform: rotate(180deg);
    position: relative;

    &::before {
      content: '';
      display: block;
      width: $width;
      height: 0;
      border-bottom: solid $lineWidth $color;
      position: absolute;
      bottom: $width / 2 - $lineWidth / 2;
      transform: rotate(90deg);
      transition: width 0.3s; }

    &::after {
      content: '';
      display: block;
      width: $width;
      height: 0;
      border-bottom: solid $lineWidth $color;
      position: absolute;
      bottom: $width /2 - $lineWidth/2; } }

  &:not(.collapsed) .animated-icon {
    transform: rotate(0deg); }

  &:not(.collapsed) .animated-icon::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-bottom: solid $lineWidth $color;
    position: absolute;
    bottom: $width / 2 - $lineWidth / 2;
    transform: rotate(90deg); } }
