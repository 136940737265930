@import '../basic/variables';

.footer {
  background-color: $daintree;
  padding: 6rem 2rem;

  @include media-tablet {
    padding: 6rem 5rem 4rem; }

  @include media-desktop-large {
    padding: 6rem 9.5rem 4rem; }

  &__firstrow {
    display: flex;
    margin-bottom: 3rem;

    @include media-desktop {
      margin-bottom: 5.3rem; } }

  &__secondrow {
    margin-bottom: 3rem;

    @include media-desktop {
      display: none; } }

  &__copyright {
    p {
      margin-bottom: 0;
      font-size: 1.6rem; } }

  &__socialmedia {
    margin-left: auto;

    a {
      margin-right: 1.3rem;

      img {
        width: 3.5rem;
        height: auto;

        &:hover {
          filter: brightness(.8); } } } }

  &__content {
    display: flex;

    &-left {
      .footer__container-text {
        color: $white;

        p, .subline {
          font-size: 1.6rem; }

        p {
          margin-bottom: 1.6rem; }

        .subline {
          margin-bottom: 4rem;
          font-weight: bold; }

        h3 {
          margin-bottom: 1.4rem; } }

      .footer__container-menu {
        margin-top: 4rem;

        .nav {
          padding-bottom: 0.8rem;
          display: block;

          @include media-tablet {
            display: flex; }

          .nav-item {
            display: flex;
            align-items: center;

            a {
              padding: 0;
              margin-right: 1rem;
              font-size: 1.6rem;
              color: $white;
              font-weight: bold;
              border-bottom: 0.1rem solid transparent;
              cursor: pointer;

              &:hover {
                border-bottom-color: $white; } }

            &:not(:last-child) {
              position: relative;

              @include media-tablet {
                &:after {
                  content: '';
                  display: block;
                  width: .2rem;
                  height: .2rem;
                  border-radius: .5rem;
                  background: $white;
                  margin-right: 1rem; } } } } } } }

    &-right {
      display: none;
      align-self: flex-end;
      margin-left: auto;
      padding-left: 3rem;
      white-space: nowrap;

      @include media-desktop {
        display: block; } } } }

