.text-image-5050 {
  display: flex;
  flex-wrap: wrap;

  @include media-desktop {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: stretch;
    min-height: 95rem;

    &--reverse {
      flex-direction: row; } }

  &__image-block {
    min-height: 45rem;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &--green-bg {
      background: $daintree; }

    @include media-desktop {
      width: 50%;
      height: initial; }

    div {
      height: 100%; }

    img {
      width: calc(100% + 14rem);
      height: calc(100% + 14rem);
      position: relative;
      left: -7rem;
      object-fit: cover;
      transform: translateY(-14rem);

      @include media-desktop {
        width: calc(100% + 40rem);
        height: calc(100% + 40rem);
        transform: translateY(-40rem);
        left: -20rem; } }

    &--contain {
      overflow: visible;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        left: 0;
        max-height: 97rem;
        transform: translateY(-10rem);

        @include media-desktop {
          transform: translateY(-16rem); } } } }

  &__text-block {
    padding: 7rem 2rem;
    background: $daintree;
    color: $white;
    width: 100%;
    overflow: hidden;

    >div {
      height: 100%; }

    @include media-desktop {
      padding: 8rem 5rem;
      width: 50%; }

    @include media-desktop-large {
      padding: 15rem 9.5rem; } }

  &__text-inner {
    transform: none;
    opacity: 0;

    @include media-desktop {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + 6rem));
      max-width: 60rem; } } }
