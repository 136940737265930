.video-module {
  position: relative;
  margin: 8rem auto;
  @include media-tablet() {
    margin: 12rem auto; }
  @include media-small-widescreen() {
    margin: 12rem; }

  video {
    width: 100%;
    height: 100%; }

  button {
    z-index: 1;
    color: #fff;
    border: none;
    display: block;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: none;
    background-color: transparent;

    &:hover {
      .video-module__play-wrap::before {
        opacity: 1; }

      .video-module__poster {
        transform: scale(1.05); } }


    .video-module__poster-wrap {
      overflow: hidden; }

    .video-module__poster {
      width: 100%;
      height: 100%;
      display: block;
      transition: transform .3s $transition-timing-function; }

    .video-module__play-wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%,-50%,0);

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: rotate(360deg) translate3d(-31%, -52%, 0);
        border-style: solid;
        border-width: 23px 0 23px 34px;
        border-color: transparent transparent transparent $white;
        z-index: 1;
        opacity: 0;
        transition: opacity .3s $transition-timing-function; } }

    &:hover {
      cursor: pointer; }
    &:active {
      box-shadow: none; }
    &:focus {
      box-shadow: none; } } }
