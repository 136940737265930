// BASICS

* {
  font-family: $font-regular; }

html {
  // 16px * 0.625 = 10px
  font-size: 0.625em; }

strong {
  @include font-bold;
  font-weight: 400; }

// HEADLINES

h1 {
  // $font-size, $font-size-tablet, $font-size-desktop-large
  @include headline-style(3.4rem, 4.6rem, 5.6rem);
  line-height: 4rem;
  margin: .8rem 0 1.6rem 0;
  @include media-tablet() {
    line-height: 5rem;
    margin: 1.2rem 0 2rem 0; }
  @include media-desktop-large() {
    line-height: 6.2rem;
    margin: 1.6rem 0 3.2rem 0; } }

h2, h1.h2-style {
  // $font-size, $font-size-tablet, $font-size-desktop-large
  @include headline-style(2.8rem, 4.2rem, 4.8rem);
  line-height: 3.4rem;
  margin: .8rem 0 1.6rem 0;
  @include media-tablet() {
    line-height: 5rem;
    margin: 1.2rem 0 2rem 0; }
  @include media-desktop-large() {
    line-height: 5.7rem;
    margin: 1.6rem 0 3.2rem 0; } }

h3 {
  // $font-size, $font-size-tablet, $font-size-desktop-large
  @include headline-style(2.2rem, 3rem, 3.6rem);
  line-height: 3rem;
  margin: .8rem 0 1.6rem 0;
  @include media-tablet() {
    line-height: 3.8rem;
    margin: 1.2rem 0 2rem 0; }
  @include media-desktop-large() {
    line-height: 4.3rem;
    margin: 1.6rem 0 3.2rem 0; } }

h4 {
  // $font-size, $font-size-tablet, $font-size-desktop-large
  @include headline-style(1.8rem, 2.4rem, 2.4rem);
  line-height: 2.4rem;
  margin: 1.6rem 0;
  @include media-tablet() {
    line-height: 2.6rem;
    margin: 2rem 0; }
  @include media-desktop-large() {
    line-height: 2.8rem;
    margin: 1.6rem 0; } }

p:not(:empty), .main__text-element > ul, ul, .main__text-element> ol, ol, dl {
  // $font-size, $font-size-tablet, $font-size-desktop-large
  @include paragraph-style(1.6rem, 1.8rem, 2rem);
  margin: .8rem 0 1.6rem 0;
  + h1, + h2, + h3, + h4 {
    margin-top: 2.4rem; }

  @include media-tablet() {
    margin: 1rem 0 1.8rem;
    + h1, + h2, + h3, + h4 {
      margin-top: 2.8rem; } }

  @include media-desktop-large() {
    margin: 1.6rem 0 3.2rem 0;
    + h1, + h2, + h3, + h4 {
      margin-top: 4.8rem; } } }

// DARK BG & HYPHENS

.text-wrap.white {
  p, strong, li, a {
    color: $white; } }

h1, h2, h3, h4, p, strong {
  &.white {
    color: white; } }

// LISTS

ul, ol {
  padding-left: 2rem;

  li {
    margin-bottom: 1rem; } }

p {
  + ul, + ol {
    margin-top: -.8rem;

    @include media-desktop {
      margin-top: -1rem; }

    @include media-desktop-large {
      margin-top: -1.6rem; } } }

ul {
  li {
    list-style-type: none;
    display: flex;

    &:before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      margin-right: .8rem;
      margin-top: .3rem;
      flex-shrink: 0;
      background-image: url("../../icons/checkbox-dark.svg");

      @include media-desktop {
        margin-top: .6rem; } } }

  &.white {
    li:before {
      background-image: url("../../icons/checkbox-light.svg"); } } }
